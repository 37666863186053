/* eslint-disable */
import React, {
    createContext,
    useState,
    useContext,
    useEffect,
} from 'react';
import _cloneDeep from 'lodash.clonedeep';
import Cookies from 'js-cookie';
import { WebsocketContext } from '../config/reducer/Websocket';
import { APIGetAlerts } from '../config/restAPI/FetchAPI';
import { APIPatchAlerts } from '../config/restAPI/PatchAPI';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const payloadContext = useContext(WebsocketContext);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const token = Cookies.get('jwtToken');
    const agencyID = Cookies.get('agencyUUID');

    useEffect(() => {
        if (data) {
            setUnreadCount(data.filter((x) => x.read === false).length);
        }
    }, [data]);

    useEffect(() => {
        if (payloadContext.newPayloadId) {
            if (payloadContext.websocketData?.type === 'alert') {
                const cloneData = _cloneDeep(data);
                cloneData.unshift(payloadContext.websocketData.data);
                setData([...cloneData]);
            }
        }
    })

    const fetchAlerts = async () => {
        try {
            setLoading(true);
            const result = await APIGetAlerts(setLoading, token, agencyID);
            setData(result?.data?.data || []);
            setLoading(false);
        } catch (err) {
            setLoading(false);
        }
    };

    const markAlertAsRead = async (oid) => {
        try {
            const params = { input: { oids: [oid], read: true } };
            const result = await APIPatchAlerts(token, setLoading, agencyID, params);
            if (result?.data?.status === 200) {
                setData(prevData => prevData.map(alert =>
                    alert.oid === oid ? { ...alert, read: true } : alert
                ));
            }
        } catch (err) {
            console.error("Error marking alert as read", err);
        }
    };

    useEffect(() => {
        fetchAlerts();
    }, []);

    return (
        <NotificationContext.Provider value={{ data, setData, unreadCount, setUnreadCount, fetchAlerts, markAlertAsRead }}>
            {children}
        </NotificationContext.Provider>
    );
};